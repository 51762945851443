import React from 'react';
import './ContactSection.css';
import { Formik } from 'formik';
import { Alert, Button, Row, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import Autocomplete from "react-google-autocomplete";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import NumberFormat from 'react-number-format';
import LoadingOverlay from 'react-loading-overlay-ts';
import validator from 'validator';

class ContactSection extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            error_visible: false, 
            show_success: false,
            address: '',
            name: '', 
            phone: '', 
            email: '',
            price: '',
            date: '',
            time: 'Any',
            withagent: false,
            sending: false
        };
        this.future_date = new Date();
    }

    topFunction = () => {
        const scrolldiv = document.getElementById("contact");
        window.scrollTo(0, scrolldiv.offsetTop);
      }

    onDismiss = () => {
        this.setState({
            error_visible: false
        });
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value })

    onCheckChange = (e) => this.setState({ [e.target.name]: e.target.checked })

    getValues = () => {return {
        address: this.state.address,
        name: this.state.name, 
        phone: this.state.phone, 
        email: this.state.email,
        price: this.state.price,
        date: this.state.date,
        time: this.state.time,
        withagent: this.state.withagent
    }}

    render(){
        return(
            <div>

            {(!this.state.show_success) &&
                <LoadingOverlay
                active={this.state.sending}
                spinner
                text='Sending request...'
                >
                    <div className="text-center">
						<h2 className="section-heading text-uppercase text-white">Get Your Offer</h2>
					</div>
                    <Formik
                    enableReinitialize={true}
                    initialValues={
                        this.getValues()}
                    validate={values => {
                        const errors = {};

                        // Address validation
                        if (!values.address) {
                            errors.address = 'Address Required';
                        }
                        else if (values.address.length >= 400){
                            errors.address = 'Address must be less than 400 charactors.';
                        }

                        // Name validation
                        if (!values.name) {
                            errors.name = 'Name Required';
                        }
                        else if (values.name.length >= 400){
                            errors.name = 'Name must be less than 400 charactors.';
                        }

                        // Phone validation
                        if (!values.phone) {
                            errors.phone = 'Phone Required';
                        } else if (!validator.isMobilePhone(values.phone)){
                            errors.phone = 'Invalid phone number';
                        }

                        // Email validation
                        if (!values.email) {
                            errors.email = 'Email Required';
                        } else if (!validator.isEmail(values.email)){
                            errors.email = 'Invalid email address';
                        }else if (values.email.length >= 400){
                            errors.email = 'Email must be less than 400 charactors.';
                        }

                        // Price validation
                        if(values.price){
                            if (values.price > 100000000){
                                errors.price = 'Please enter desired price less than $100,000,000.';
                            }
                        }
                        
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        window.gtag('event', 'conversion', {
                            'send_to': 'AW-11062909031/eCu8CL6Dr4cYEOewmpsp'
                        });
                        this.setState({
                            sending: true
                        });
                        axios.post(process.env.REACT_APP_CONTACT_URL, this.getValues())
                        .then(response => {
                            this.setState({
                                error_visible: false,
                                show_success: true,
                                sending: false
                            }, () => {
                                this.topFunction();
                            });
                            setSubmitting(false);
                        })
                        .catch(error => {
                            this.setState({
                                error_visible: true,
                                sending: false
                            });
                            setSubmitting(false);
                        });
                    }}
                    >
                    {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    }) => (
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col className="col-md-12">
                                <FormGroup floating>
                                    <Autocomplete
                                        id="address"
                                        className="form-control"
                                        debounce="1000"
                                        name="address"
                                        placeholder="Property Address"
                                        style={{ width: "100%" }}
                                        onPlaceSelected={(place) => {
                                            values.address = place.formatted_address;
                                            this.setState({
                                                address: place.formatted_address
                                            });
                                        }}
                                        options={{
                                            types: ["address"],
                                            componentRestrictions: { country: "us" },
                                        }}
                                        onChange={this.onChange}
                                        value={this.state.address}
                                    />
                                    <Label for="address">Property Address</Label>
                                    <div className="invalid-feedback">{errors.address && touched.address && errors.address}</div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                <FormGroup floating>
                                    <Input
                                        placeholder="Name"
                                        type="text"
                                        name="name"
                                        onChange={this.onChange}
                                        value={this.state.name}
                                    />
                                    <Label for="name">Name</Label>
                                    <div className="invalid-feedback">{errors.name && touched.name && errors.name}</div>
                                </FormGroup>
                            </Col>
                            <Col className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                <FormGroup floating className="form-group">
                                    <NumberFormat id="phone" format="+1 (###) ###-####" mask="_" name="phone" placeholder="Phone Number" onChange={this.onChange} value={this.state.phone} className="form-control" />
                                    <Label for="phone">Phone Number</Label>
                                    <div className="invalid-feedback">{errors.phone && touched.phone && errors.phone}</div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                <FormGroup floating>
                                    <Input
                                        placeholder="Email"
                                        id="email"
                                        type="email"
                                        name="email"
                                        onChange={this.onChange}
                                        value={this.state.email}
                                    />
                                <Label for="email">Email</Label>
                                <div className="invalid-feedback">{errors.email && touched.email && errors.email}</div>
                                </FormGroup>
                            </Col>
                            <Col className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                <FormGroup floating>
                                    <Input className="form-control" type="select" name="time" id="time" onChange={this.onChange} value={this.state.time} placeholder="Preferred Contact Time (Optional)">
                                        <option>Any</option>
                                        <option>8am-10am MST</option>
                                        <option>10am-12pm MST</option>
                                        <option>12pm-2pm MST</option>
                                        <option>2pm-4pm MST</option>
                                    </Input>
                                    <Label className="text-black" for="time">Preferred Contact Time (Optional)</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <FormGroup className='text-center align-middle'>
                                    <Input className="mt-0" type="checkbox" name="withagent" id="withagent" onChange={this.onCheckChange} value={this.state.withagent} />
                                    <Label className="text-white align-middle ps-2 mb-0" for="withagent">I am working with a Real Estate Agent</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-md-12">
                                <Alert className="text-center" color="danger" isOpen={this.state.error_visible} toggle={this.onDismiss}>
                                    There has been an error sending your request, please try again! Or contact us at support@estatewbros.com
                                </Alert>
                                <div className="text-center my-3">
                                    <Button size="lg" color="primary" type="submit" disabled={isSubmitting}>
                                    Send Request
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    )}
                    </Formik>
                </LoadingOverlay>
            }
            {(this.state.show_success) &&
                <div className="text-center">
                    <h3 className="section-subheading text-muted text-white text-center align-items-center">
                        <span className="fa-stack fa-1x">
                            <FontAwesomeIcon color="green" icon={faCheckCircle} />
                        </span>
                        Thank you for contacting us! We will get back to you shortly.
                    </h3>
                </div>
                
            }
          </div>
        );  
    }
}

export default ContactSection;